<template>
  <div class="charecteristc main-block">
    <div class="main-block__head">
      <h1 class="main-block__title">Статистика рейсов </h1>
    </div>
    <div class="charecteristc__block row">
      <div class="col">
        <div class="stats">
          <span class="dot orange"></span> <span class="count">{{ this.success_count }}</span> <br> <br>
          <div class="flex">
            <span class="title">Успешных рейсов</span> <span class="percent">{{ this.total_count ? Number((this.success_count/this.total_count*100).toFixed(this.toFixed)) : 0 }} %</span>
          </div>
        </div>
        <div class="stats">
          <span class="dot green"></span> <span class="count">{{ this.rejected_count }}</span> <br> <br>
          <div class="flex">
            <span class="title">Отказано в маршруте</span> <span class="percent">{{ this.total_count ? Number((this.rejected_count/this.total_count*100).toFixed(this.toFixed)) : 0 }} %</span>
          </div>
        </div>
        <div class="stats">
          <span class="dot blue"></span> <span class="count">{{ this.broken_count }}</span> <br> <br>
          <div class="flex">
            <span class="title">Рейсы с поломками</span> <span class="percent">{{ this.total_count ? Number((this.broken_count/this.total_count*100).toFixed(this.toFixed)) : 0 }} %</span>
          </div>
        </div>
        

      </div>
      <div class="col">
        <apexchart type="donut" :options="options" :series="series" />
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  name: "Statistic",
  title: "Transport statistics",
  props: {
    allCarsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      toFixed: 0,
      success_count: 0,
      rejected_count: 0,
      broken_count: 0,
    };
  },
  computed: {
    series() {
      return [
        this.broken_count, this.rejected_count, this.success_count
      ];
    },
    options() {
      return {
        labels: ['Рейсы с поломками', 'Отказано в маршруте', 'Успешных рейсов'],
        chart: {
          type: 'donut'
        },
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return Number((val).toFixed(0)) + "%"
          },
          style: {}
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            customScale: 0.8,
            donut: {
              size: '90%',
              labels: {
                show: true,
                name: {
                  
                },
                value: {

                },
                total: {
                  show: true,
                  label: "За последний год",
                  color: '#000000',
                }
              }
            }
          }
        }
      }
    },
    total_count() {
      return this.success_count + this.rejected_count + this.broken_count
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$api
        .get("/web/transport/statistic/" + this.allCarsData.id)
        .then((response) => {
          this.success_count = response.data.success_count
          this.rejected_count = response.data.rejected_count
          this.broken_count = response.data.broken_count
        })
        .catch(() => {
          this.$toast.error('Что-то пошло не так, попробуйте позднее!');
        });
    },
  }
};
</script>

<style scoped>
.charecteristc__block {
  align-items: flex-start;
}
.dot {
  width: 15px;
  height: 15px;
  border-radius: 1px;
  display: inline-block;
}
.green {
  background: rgb(0, 227, 150);
}
.orange {
  background: rgb(254, 176, 25);
}
.blue {
  background: rgb(0, 143, 251)
}
.title {
  margin-left: 18px;
  font-weight: 400;
}
.count {
  font-size: 20px;
}
.stats {
  margin: 30px 0 0 30px;
  font-size: 14px;
}
.percent {
  color: #ACACAC
}
</style>
